/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./camera-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "../../pipes/localize.pipe";
import * as i9 from "../../../services/localization.service";
import * as i10 from "@angular/material/dialog";
import * as i11 from "@angular/common";
import * as i12 from "./camera-upload.component";
var styles_CameraUploadComponent = [i0.styles];
var RenderType_CameraUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CameraUploadComponent, data: {} });
export { RenderType_CameraUploadComponent as RenderType_CameraUploadComponent };
function View_CameraUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.enterFullScreen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["open_in_full"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
function View_CameraUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exitFullScreen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close_fullscreen"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
function View_CameraUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["width", "200px"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "image-remove mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeImage(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["cancel"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).inline; var currVal_2 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
export function View_CameraUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.LocalizePipe, [i9.LocalizationService]), i1.ɵqud(671088640, 1, { video: 0 }), i1.ɵqud(671088640, 2, { videoContainer: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 27, "div", [["class", "camera-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 16, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i10.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, [[2, 0], ["videoContainer", 1]], null, 11, "div", [["style", "display: none;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["video", 1]], null, 0, "video", [["autoplay", ""], ["muted", ""], ["playsinline", ""], ["width", "100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 9, "div", [["class", "control-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.takePhoto() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(12, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add_a_photo"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CameraUploadComponent_1)), i1.ɵdid(15, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CameraUploadComponent_2)), i1.ɵdid(17, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "list-images"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CameraUploadComponent_3)), i1.ɵdid(20, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(22, 16384, null, 0, i10.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(23, 0, null, null, 3, "button", [["class", "btn btn__secondary btn-cancel"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(24, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(25, 0, ["", ""])), i1.ɵppd(26, 1), (_l()(), i1.ɵeld(27, 0, null, null, 3, "button", [["class", "btn btn__primary"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.accept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(28, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(29, 0, ["", ""])), i1.ɵppd(30, 1)], function (_ck, _v) { var _co = _v.component; _ck(_v, 12, 0); var currVal_4 = !_co.isInFullScreen; _ck(_v, 15, 0, currVal_4); var currVal_5 = _co.isInFullScreen; _ck(_v, 17, 0, currVal_5); var currVal_6 = _co.images; _ck(_v, 20, 0, currVal_6); var currVal_12 = (_co.images.length == 0); _ck(_v, 28, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 10).disabled || null); var currVal_1 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 12).inline; var currVal_3 = (((i1.ɵnov(_v, 12).color !== "primary") && (i1.ɵnov(_v, 12).color !== "accent")) && (i1.ɵnov(_v, 12).color !== "warn")); _ck(_v, 11, 0, currVal_2, currVal_3); var currVal_7 = (i1.ɵnov(_v, 24).disabled || null); var currVal_8 = (i1.ɵnov(_v, 24)._animationMode === "NoopAnimations"); _ck(_v, 23, 0, currVal_7, currVal_8); var currVal_9 = i1.ɵunv(_v, 25, 0, _ck(_v, 26, 0, i1.ɵnov(_v, 0), "Cancel")); _ck(_v, 25, 0, currVal_9); var currVal_10 = (i1.ɵnov(_v, 28).disabled || null); var currVal_11 = (i1.ɵnov(_v, 28)._animationMode === "NoopAnimations"); _ck(_v, 27, 0, currVal_10, currVal_11); var currVal_13 = i1.ɵunv(_v, 29, 0, _ck(_v, 30, 0, i1.ɵnov(_v, 0), "Accept")); _ck(_v, 29, 0, currVal_13); }); }
export function View_CameraUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-camera-upload", [], null, null, null, View_CameraUploadComponent_0, RenderType_CameraUploadComponent)), i1.ɵdid(1, 114688, null, 0, i12.CameraUploadComponent, [i10.MatDialogRef, i10.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CameraUploadComponentNgFactory = i1.ɵccf("app-camera-upload", i12.CameraUploadComponent, View_CameraUploadComponent_Host_0, {}, {}, []);
export { CameraUploadComponentNgFactory as CameraUploadComponentNgFactory };
