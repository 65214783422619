export const environment = {
  production: true,
  env: 'prod',
  securityUrl: 'https://9rbidca60b.execute-api.us-west-2.amazonaws.com/Prod/api',
  
  clientUrl: 'https://geudjszxJhAxHprod.api.puppis.com.ar/api/v1/',
  

  productUrl: 'https://f418dpjf0f.execute-api.us-west-2.amazonaws.com/Prod/api',
  supplyingUrl: 'https://pl2o8beh50.execute-api.us-west-2.amazonaws.com/Prod',
  omsLiteUrl: 'https://4b7cq14pw9.execute-api.us-west-2.amazonaws.com/Prod',
  omsUrl: 'https://fosunq0i98.execute-api.us-west-2.amazonaws.com/Prod/',
  salesUrl: 'https://mhrkq877lb.execute-api.us-west-2.amazonaws.com/Prod',
  settingsUrl: 'https://hx9mcxz513.execute-api.us-west-2.amazonaws.com/Prod',
  productsLiteUrl: 'https://ioby4orq7k.execute-api.us-west-2.amazonaws.com/Prod',
  storesUrl: 'https://biaazj8kx1.execute-api.us-west-2.amazonaws.com/Prod',
  maillingUrl: 'https://4zrf96q7yd.execute-api.us-west-2.amazonaws.com/Prod/api',
  wikiUrl: 'https://naq6q24630.execute-api.us-west-2.amazonaws.com/Prod/api',
  logViewerUrl: 'https://pmk1iq9pzd.execute-api.us-west-2.amazonaws.com/Prod',
  transfersUrl: 'https://coq2lkjdrl.execute-api.us-west-2.amazonaws.com/Prod',
  notificationsUrl: 'https://2hh2wsvl7d.execute-api.us-west-2.amazonaws.com/Prod',
  wmsUrl: 'https://umnc5tmorh.execute-api.us-west-2.amazonaws.com/Prod',
  vetServiceUrl: 'https://api.servicios.puppis.com.ar',
  singleCustomerUrl: 'https://csmxtjfz12.execute-api.us-west-2.amazonaws.com/Prod',
  puppisRepurchase: "https://butfzbxinj.execute-api.us-west-2.amazonaws.com/Prod",
  AUTH_CONFIG: {
    issuer: 'https://accounts.google.com',
    redirectUri: `${window.location.origin}/login`,
    clientId: '823529633973-h4ponfb7ra82n1256ovqo8bbiobf3pp4.apps.googleusercontent.com',
    scope: 'openid profile email',
    strictDiscoveryDocumentValidation: false,
    secret: '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'
  },
};
