<div class="camera-dialog">
    <!-- <h3 mat-dialog-title>{{'AddPicture' | localize}}</h3> -->
    <mat-dialog-content>
        <div #videoContainer style="display: none;">
            <video #video autoplay muted playsinline width="100%"></video>
            <div class="control-container">
                <button mat-icon-button (click)="takePhoto()">
                    <mat-icon>add_a_photo</mat-icon>
                </button>
                <button *ngIf="!isInFullScreen" mat-icon-button (click)="enterFullScreen()">
                    <mat-icon>open_in_full</mat-icon>
                </button>
                <button *ngIf="isInFullScreen" mat-icon-button (click)="exitFullScreen()">
                    <mat-icon>close_fullscreen</mat-icon>
                </button>
            </div>
        </div>

        <div class="list-images">
            <div *ngFor="let image of images; let i = index">
                <img [src]="image" width="200px" />
                <mat-icon class="image-remove" (click)="removeImage(i)">cancel</mat-icon>
                <!-- <button mat-button class="image-remove" (click)="removeImage(i)">
                    <mat-icon>cancel</mat-icon>
                </button> -->
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button class="btn btn__secondary btn-cancel" (click)="close()">{{'Cancel' | localize}}</button>
        <!-- <button mat-button class="btn btn__primary" (click)="openCamera()" [disabled]="images.length >= 3" >{{'AddPicture' | localize}}</button> -->
        <button mat-button class="btn btn__primary" (click)="accept()" [disabled]="images.length == 0">{{'Accept' |
            localize}}</button>
    </mat-dialog-actions>
</div>