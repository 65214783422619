import { Component, ViewChild, ElementRef, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-camera-upload',
    templateUrl: './camera-upload.component.html',
    styleUrls: ['./camera-upload.component.scss'],
})
export class CameraUploadComponent implements OnInit {
    @ViewChild('video', { static: false }) video: ElementRef;
    @ViewChild('videoContainer', { static: false }) videoContainer: ElementRef;
    images: string[] = [];
    stream: MediaStream;
    isInFullScreen: boolean;

    constructor(
        private thisDialogRef: MatDialogRef<CameraUploadComponent, string[]>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        if (data.images) this.images = [...data.images];
    }

    ngOnInit(): void {
        if (this.images.length < 3) this.openCamera();
    }

    removeImage(index: number) {
        this.images.splice(index, 1);
        if (this.images.length < 3) this.openCamera();
    }

    async openCamera() {
        try {
            if (this.stream) {
                this.stopCamera();
            }

            this.stream = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: { facingMode: 'environment' },
            });
            this.video.nativeElement.srcObject = this.stream;
            this.video.nativeElement.play();
            this.videoContainer.nativeElement.style.display = 'block';
            // this.enterFullScreen();
        } catch (error) {
            console.error('Error accessing camera:', error);
            alert(`Unable to access the camera.`);
            this.video.nativeElement.srcObject = null;
            this.videoContainer.nativeElement.style.display = 'none';
        }
    }

    stopCamera() {
        if (this.stream) {
            const tracks = this.stream.getTracks();
            tracks.forEach((track) => track.stop());
            this.video.nativeElement.srcObject = null;
            this.videoContainer.nativeElement.style.display = 'none';
        }
    }

    enterFullScreen() {
        if (this.videoContainer.nativeElement.requestFullscreen) {
            this.videoContainer.nativeElement.requestFullscreen();
        } else if (this.videoContainer.nativeElement.mozRequestFullScreen) {
            // Firefox
            this.videoContainer.nativeElement.mozRequestFullScreen();
        } else if (this.videoContainer.nativeElement.webkitRequestFullscreen) {
            // Chrome, Safari y Opera
            this.videoContainer.nativeElement.webkitRequestFullscreen();
        } else if (this.videoContainer.nativeElement.msRequestFullscreen) {
            // IE/Edge
            this.videoContainer.nativeElement.msRequestFullscreen();
        } else {
            console.error('Fullscreen not supported.');
            alert('Unable to enter in fullscreen mode.');
            return;
        }

        this.isInFullScreen = true;
    }

    exitFullScreen() {
        document.exitFullscreen();
        this.isInFullScreen = false;
    }

    takePhoto() {
        const videoElement = this.video.nativeElement;
        const canvas = document.createElement('canvas');
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        this.images.push(canvas.toDataURL('image/png'));
        if (this.images.length === 3) this.stopCamera();
    }

    accept() {
        this.stopCamera();
        this.thisDialogRef.close(this.images);
    }

    close() {
        this.stopCamera();
        this.thisDialogRef.close();
    }
}
