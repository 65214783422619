import { __awaiter, __generator, __read, __spread } from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var CameraUploadComponent = /** @class */ (function () {
    function CameraUploadComponent(thisDialogRef, data) {
        this.thisDialogRef = thisDialogRef;
        this.images = [];
        if (data.images)
            this.images = __spread(data.images);
    }
    CameraUploadComponent.prototype.ngOnInit = function () {
        if (this.images.length < 3)
            this.openCamera();
    };
    CameraUploadComponent.prototype.removeImage = function (index) {
        this.images.splice(index, 1);
        if (this.images.length < 3)
            this.openCamera();
    };
    CameraUploadComponent.prototype.openCamera = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        if (this.stream) {
                            this.stopCamera();
                        }
                        _a = this;
                        return [4 /*yield*/, navigator.mediaDevices.getUserMedia({
                                audio: false,
                                video: { facingMode: 'environment' },
                            })];
                    case 1:
                        _a.stream = _b.sent();
                        this.video.nativeElement.srcObject = this.stream;
                        this.video.nativeElement.play();
                        this.videoContainer.nativeElement.style.display = 'block';
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        console.error('Error accessing camera:', error_1);
                        alert("Unable to access the camera.");
                        this.video.nativeElement.srcObject = null;
                        this.videoContainer.nativeElement.style.display = 'none';
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CameraUploadComponent.prototype.stopCamera = function () {
        if (this.stream) {
            var tracks = this.stream.getTracks();
            tracks.forEach(function (track) { return track.stop(); });
            this.video.nativeElement.srcObject = null;
            this.videoContainer.nativeElement.style.display = 'none';
        }
    };
    CameraUploadComponent.prototype.enterFullScreen = function () {
        if (this.videoContainer.nativeElement.requestFullscreen) {
            this.videoContainer.nativeElement.requestFullscreen();
        }
        else if (this.videoContainer.nativeElement.mozRequestFullScreen) {
            // Firefox
            this.videoContainer.nativeElement.mozRequestFullScreen();
        }
        else if (this.videoContainer.nativeElement.webkitRequestFullscreen) {
            // Chrome, Safari y Opera
            this.videoContainer.nativeElement.webkitRequestFullscreen();
        }
        else if (this.videoContainer.nativeElement.msRequestFullscreen) {
            // IE/Edge
            this.videoContainer.nativeElement.msRequestFullscreen();
        }
        else {
            console.error('Fullscreen not supported.');
            alert('Unable to enter in fullscreen mode.');
            return;
        }
        this.isInFullScreen = true;
    };
    CameraUploadComponent.prototype.exitFullScreen = function () {
        document.exitFullscreen();
        this.isInFullScreen = false;
    };
    CameraUploadComponent.prototype.takePhoto = function () {
        var videoElement = this.video.nativeElement;
        var canvas = document.createElement('canvas');
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        var context = canvas.getContext('2d');
        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        this.images.push(canvas.toDataURL('image/png'));
        if (this.images.length === 3)
            this.stopCamera();
    };
    CameraUploadComponent.prototype.accept = function () {
        this.stopCamera();
        this.thisDialogRef.close(this.images);
    };
    CameraUploadComponent.prototype.close = function () {
        this.stopCamera();
        this.thisDialogRef.close();
    };
    return CameraUploadComponent;
}());
export { CameraUploadComponent };
